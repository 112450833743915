import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../styles/core.css"
import "../styles/styles.css"
import Layout from "../components/Layout"
import MenuOne from "../components/MenuOne"
import Gallery from "../components/Gallery"
import Content from "../components/Content"

const galImgs = ["/page-images/crockard_map_0.jpg"]

export default function Home() {
  return (
  <Layout breadcrumbs={["Contact us"]}>
    <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

 <Content>

 <p>Crockard Building Design</p>

 <p>	24 Ballyalgan Road</p>

 <p>	Crossgar, Downpatrick</p>

 <p>	County Down</p>

 <p>	BT30 9DR</p>

 <p>	 </p>
 <p>	T:  028 4483 1566</p>
 <p>	E:  <a href="mailto:info@cbd-architecture.com">info@cbd-architecture.com</a></p>
 <p>	W: <a href="http://www.cbd-architecture.com" title="www.cbd-architecture.com">www.cbd-architecture.com</a></p>

 </Content>

    <div className="region region-content-bottom">
      <MenuOne />
    </div>

  </Layout>

  )
}
